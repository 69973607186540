@import "vars";
@import "Grid";

@include generate-grid-columns;

header{
  .logo{
    max-width:250px;
  }
}

.thin-container{
  max-width:720px;
  width:100%;
  margin:0 auto;
}

.additional-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.expanded {
  max-height: 500px; /* Adjust this value based on the content size or make it large enough to handle dynamic content */
}

.collapsed {
  max-height: 0;
}

.code-animation-container {
  width: calc(100% - 30px);
  overflow: hidden;
  max-width:520px;
  height: 350px;
  margin: 0;
  padding: 5px 15px;
  font-weight:500;
  background-color: $creamy-color;  // Dark theme, like many code editors
  color: $red-color;  // Light gray text color for better contrast
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  white-space: pre-wrap;
  position: relative;
  line-height: 1.8;
  @media screen and (max-width: 570px){
    max-width:430px;
    font-size: 14px;
  }

  @media screen and (max-width: 420px){
    max-width:300px;
    font-size: 13px;
  }

}

.cursor {
  display: inline-block;
  width: 8px;
  height: 20px;
  background-color: $red-color;  // Same color as text for a seamless look
  animation: blink 1s infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

.contact-form {
  margin: 2rem auto;
  width: 100%;

  form {
    display: grid;
    grid-template-columns: repeat(4, 1fr); // Creates four columns on larger screens
    grid-gap: 20px;

    input, select, textarea {
      padding: 0.8rem;
      border: 1px solid lighten($black-color, 30%);
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      background-color: transparent;
      &:focus {
        border-color: darken($black-color, 10%);
        outline: none;
      }
    }

    textarea {
      grid-column: 1 / -1; // Makes textarea span all columns
      height: 150px;
      resize: none;
    }

    button {
      grid-column: 1 / -1; // Makes button span all columns
      cursor: pointer;
      background-color: $red-color;
      color: $creamy-color;
      border: none;
      padding: 1rem 2rem;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: bold;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($red-color, 10%);
      }
    }
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    form {
      grid-template-columns: 1fr; // Makes everything stack in one column on smaller screens
    }
  }
}

.form-error {
  color: $red-color; // Using a predefined red color variable for error messages
  margin-bottom: 1rem; // Space before the form starts
  text-align: center; // Center align the text
}

.form-success{
  color: green; // Using a predefined red color variable for error messages
  margin-bottom: 1rem; // Space before the form starts
  text-align: center; // Center align the text
}

