$breakpoints: (
        none: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

@mixin generate-grid-columns {
  @each $breakpoint, $width in $breakpoints {
    @for $numerator from 1 through 12 {
      @for $denominator from $numerator through 12 {
        @if $breakpoint == none {
          .col-#{$numerator}-#{$denominator} {
            width: (100% * $numerator / $denominator);
            flex: 0 0 (100% * $numerator / $denominator);
          }
        } @else {
          @media (min-width: $width) {
            .col-#{$breakpoint}-#{$numerator}-#{$denominator} {
              width: calc((100% * #{$numerator / $denominator}) - 30px); // Adjust 30px to match your padding
              flex: 0 0 calc((100% * #{$numerator / $denominator}) - 30px);
            }
          }
        }
      }
    }
  }
}


.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.position-relative{
  position:relative;
  display:inline-block;
}

.row {
  display: flex;
  flex-wrap: wrap; // Ensures items can wrap as needed
  margin-right: -15px; // Adjust these based on your actual padding/margin strategy
  margin-left: -15px;
  @media screen and (max-width: 576px){
    margin:0 auto;
  }
}

.align-center{
  align-items: center;
}


[class*="col-"] {
  position: relative;
  padding-right: 15px; // Ensure these are included in your calculations
  padding-left: 15px;
}


@media (max-width: 576px) {
  [class*="col-"] {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0; // No padding on very small screens
  }
}