@import "vars";

$colors: (
        "primary": $black-color,
        "secondary": #fffefc,
        "success": #28a745,
        "danger": #dc3545,
        "warning": #ffc107,
        "info": #17a2b8,
        "light": #f8f9fa,
        "dark": #343a40,
        "white": #ffffff,
        "black": #000000,
);
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=Montserrat:wght@400;700&display=swap');

.float-left{
  float:left;
  clear: both;
}
.float-right{
  float:right;
  clear: both;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700; /* Bold weight for headers to stand out */
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; /* Normal weight for readability */
  line-height: 1.7; /* Increased line height for better text flow */
  font-size: 1em; /* Default size, can be adjusted per design needs */
}

a {
  color: #3E5060; /* Links in the same elegant dark slate as headers */
  text-decoration: none; /* No underline to keep the look clean */
}
a:hover {
  text-decoration: underline; /* Underline on hover for clarity */
}

footer{
  .footer-content{
    margin:0;
    padding:0;
    font-size:13px;
  }
}

h2{
  position: relative;
  &:after{
    content:"";
    width:30%;
    height:1px;
    background-color:$black-color;
    position: absolute;
    bottom:-10px;
    left:0;

  }
}

body {
  color: $black-color;
  background-color:$creamy-color;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  font-size:100%;
}


$container-padding: 15px;

// Generate responsive container styles
.container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 576px){
    width: calc(100% - 30px);
  }

  @each $breakpoint, $container-width in $container-max-widths {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      max-width: $container-width;
    }
  }
}

.cut-corners {
  clip-path: polygon(
                  0 0,       // top-left
                  calc(100% - 20px) 0,  // top-right, starts cut 20px left of the right edge
                  100% 20px, // slightly down from top-right corner
                  100% 100%, // bottom-right
                  20px 100%, // bottom-left, starts cut 20px right of the left edge
                  0 calc(100% - 20px)  // slightly up from bottom-left corner
  );
}

// Example of applying the class along with the utility classes
.section-cut-corners {
  @extend .cut-corners;
  @extend .py-3;
  @extend .mt-3;
  @extend .bg-primary;
  @extend .text-secondary;

  h1 {
    @extend .p-0;
    @extend .m-0;
  }
}


.cut-corners-diagonal {
  clip-path: polygon(
                  0 0,                   // top-left
                  calc(100% - 20px) 0,   // top-right, start cut 20px left of the right edge
                  100% 20px,             // slightly down from top-right corner
                  100% 100%,             // bottom-right
                  20px 100%,             // bottom-left, start cut 20px right of the left edge
                  0 calc(100% - 20px)    // slightly up from bottom-left corner
  );
  background-color: $creamy-color; // Set the background to white
  color: $black-color;            // Set text color to black
  border: 1px solid $black-color; // Outline the element with a black border
}

// Utility class application example
.section-cut-corners-diagonal {
  @extend .cut-corners-diagonal;
  @extend .mt-3;

  h2 {
    @extend .m-0;
  }
}


// Generate background and text color classes
@each $color-name, $color-value in $colors {
  .bg-#{$color-name} {
    background-color: $color-value !important;
  }
  .text-#{$color-name} {
    color: $color-value !important;
  }
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

// Font sizes
$font-size-base: 16px; // Base font size
$font-size-h1: 2.25rem; // 36px
$font-size-h2: 1.75rem; // 28px
$font-size-h3: 1.5rem;  // 24px
$font-size-h4: 1.25rem; // 20px
$font-size-h5: 1rem;    // 16px
$font-size-p: 1rem;     // 16px

// Line heights
$line-height-base: 1.5; // Base line height
$line-height-heading: 1.2; // Line height for headings

// Margin bottom for spacing
$margin-bottom: 1rem;

// Heading styles
h1, h2, h3, h4, h5, p {
  margin-bottom: $margin-bottom;
  line-height: $line-height-base;
}

h1 {
  font-size: $font-size-h1;
  line-height: $line-height-heading;
  font-weight: 600;
}

h2 {
  font-size: $font-size-h2;
  line-height: $line-height-heading;
  font-weight: 600;
}

h3 {
  font-size: $font-size-h3;
  line-height: $line-height-heading;
  font-weight: 600;
}

h4 {
  font-size: $font-size-h4;
  line-height: $line-height-heading;
  font-weight: 600;
}

h5 {
  font-size: $font-size-h5;
  line-height: $line-height-heading;
  font-weight: 600;
}

p {
  font-size: $font-size-p;
}



.corner-triangle {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;          /* Top edge aligned with the top of the viewport */
  left: 0;         /* Left edge aligned with the left of the viewport */
  width: 0;        /* Zero width - the element itself doesn't occupy space */
  height: 0;       /* Zero height - the element itself doesn't occupy space */
  z-index: 10;     /* Ensures the triangle stays on top of other content */
}

.corner-triangle::before {
  content: "";                             /* Necessary for pseudo-elements */
  position: absolute;                      /* Absolute position relative to its nearest positioned ancestor */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;             /* Creates the triangle shape */
  border-color: $black-color transparent transparent transparent; /* Black triangle */
}

.corner-triangle-bottom {
  position: fixed; /* Fixed position relative to the viewport */
  bottom: 50px;       /* Bottom edge aligned with the bottom of the viewport */
  right: 50px;        /* Right edge aligned with the right of the viewport */
  width: 0;        /* Zero width - the element itself doesn't occupy space */
  height: 0;       /* Zero height - the element itself doesn't occupy space */
  z-index: 10;     /* Ensures the triangle stays on top of other content */
}

.corner-triangle-bottom::before {
  content: "";                             /* Necessary for pseudo-elements */
  position: absolute;                      /* Absolute position relative to its nearest positioned ancestor */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;             /* Adjusts to create the triangle shape pointing upwards */
  border-color: transparent transparent $black-color transparent; /* Black triangle */
}

.App {
  text-align: center;
  border-radius: 8px;
  padding:50px 0;
}

.App-logo {
  height: 80px;
}

nav ul {
  padding: 0;
  list-style-type: none;

  li {
    display: inline;
    margin-right: 15px;
  }
}

p{
  font-size:18px;
}


