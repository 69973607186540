$primary-color: #333;
$text-color: #666;
$black-color:#232b2b;
$creamy-color:#fffefc;
$red-color: #a50202;
$green-color:#2fc218;


html {
  height: 100%;
  box-sizing: border-box;
  font-size: 16px; // Base font size
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  font-family: 'Arial', sans-serif; // Using Arial as a fallback font
  line-height: 1.6; // Good for readability
  background: #f0f0f0; // Light gray background
  color: #333; // Dark gray text color

  display: flex;
  flex-direction: column;
  align-items: center; // Center align the content
  justify-content: flex-start; // Align content to start from top
}


// Breakpoints for responsiveness
$breakpoints: (
        "sm": 576px,   // Small devices (landscape phones, 576px and up)
        "md": 768px,   // Medium devices (tablets, 768px and up)
        "lg": 992px,   // Large devices (desktops, 992px and up)
        "xl": 1200px,  // Extra large devices (large desktops, 1200px and up)
);

// Container settings
$container-max-widths: (
        "sm": 540px,
        "md": 720px,
        "lg": 960px,
        "xl": 1140px,
);
$colors: (
        "primary": $black-color,
        "secondary": #fffefc,
        "success": #28a745,
        "danger": #dc3545,
        "warning": #ffc107,
        "info": #17a2b8,
        "light": #f8f9fa,
        "dark": #343a40,
        "white": #ffffff,
        "black": #000000,
);

// Generate background and text color classes
@each $color-name, $color-value in $colors {
  .bg-#{$color-name} {
    background-color: $color-value !important;
  }
  .text-#{$color-name} {
    color: $color-value !important;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap; // Allows items to wrap as necessary
  margin: 0 -10px; // Negative margin for gutters; adjust as necessary for your design
  justify-content: space-between; // Adjusts children to space evenly

  @media (max-width: 768px) {
    margin: 0;
  }
}

.menu {
  a{
    text-decoration: none;
    color: $black-color;
    padding:3px 0;
    position: relative;
    display: inline-block;

    &:hover {
      background-color: $black-color;
      text-decoration: none;
      color:$creamy-color;
    }
    &.active {
      background-color: $red-color;
    }
  }
}


.align-right {
  justify-content: right; // Aligns child content to the right
  display: flex;
}

.align-left {
  justify-self: flex-start; // Aligns child content to the left
}



@media (max-width: 768px) {
  $font-size-h1: 2rem; // Slightly smaller on smaller screens
  $font-size-h2: 1.5rem;
  $font-size-h3: 1.25rem;
  $font-size-h4: 1.1rem;
  $font-size-h5: 0.9rem;
}

@media (max-width: 480px) {
  $font-size-h1: 1.75rem; // Even smaller on mobile screens
  $font-size-h2: 1.25rem;
  $font-size-h3: 1rem;
  $font-size-h4: 0.9rem;
  $font-size-h5: 0.8rem;
}

// Spacing values
$spacing-scales: (
        0: 0,
        1: 0.25rem,  // 4px
        2: 0.5rem,   // 8px
        3: 1rem,     // 16px
        4: 1.5rem,   // 24px
        5: 2rem,     // 32px
        6: 3rem,     // 48px
);

// Generate margin and padding utility classes
@each $key, $value in $spacing-scales {
  .m-#{$key} { margin: $value !important; }
  .mt-#{$key} { margin-top: $value !important; }
  .mr-#{$key} { margin-right: $value !important; }
  .mb-#{$key} { margin-bottom: $value !important; }
  .ml-#{$key} { margin-left: $value !important; }
  .mx-#{$key} { margin-left: $value !important; margin-right: $value !important; }
  .my-#{$key} { margin-top: $value !important; margin-bottom: $value !important; }

  .p-#{$key} { padding: $value !important; }
  .pt-#{$key} { padding-top: $value !important; }
  .pr-#{$key} { padding-right: $value !important; }
  .pb-#{$key} { padding-bottom: $value !important; }
  .pl-#{$key} { padding-left: $value !important; }
  .px-#{$key} { padding-left: $value !important; padding-right: $value !important; }
  .py-#{$key} { padding-top: $value !important; padding-bottom: $value !important; }
}

@media (max-width: 768px) {
  @each $key, $value in $spacing-scales {
    .m-#{$key}-sm { margin: $value !important; }
    .p-#{$key}-sm { padding: $value !important; }
    // Additional responsive classes as needed
  }
}